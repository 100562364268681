import React from 'react'
import dayjs from 'dayjs'
import get from 'lodash/get'
import has from 'lodash/has'
import isEmpty from 'lodash/isEmpty'
import { extractResourceTypeData, getContactEmail } from '../../utilities/apiHelpers'
import { formatToolDetailCells, toolTypes, toolIcons, toolDetails } from '../../utilities/lookupToolUtilities'
import { formatMetaTags } from './global'
import { getAssetOrigin } from '../../utilities/linkHelpers'
import {
  getState,
  getSpecialty,
  isDmeSupplier,
  getNameFromRow,
  getDueDateDisplay,
  getNpiList
} from '../../utilities/lookupToolUtilities/revalidationTool'
import { NpiTextDisplay } from '../../components/interactiveTools/NpiListModal/NpiListModal'
import config from '../../config'
const { apiSite } = config

export const SECTION_TEXT = 'text'
export const SECTION_RESOURCES = 'resources'
export const SECTION_DATASETS = 'datasets'
export const SECTION_FAQS = 'faqs'

/**
 * formats lookup tool data that is used by various pages and components
 * @param {object} rawData - JSON API deserialized response
 */
export function formatCommonLookupToolData (rawData = {}) {
  const hasTextSections = Array.isArray(rawData.fieldLookUpToolSection)
  const hasResources = !!get(rawData, 'fieldLookUpToolResourceSec.fieldLookupToolAddlResources')
  const hasFaqs = !!get(rawData, 'fieldLookupToolFaqSec')
  const hasDatasets = !!get(rawData, 'fieldLookupToolDatasetsSec')
  const dataSources = get(rawData, 'fieldPgLookupToolDatasource', [])
  const toolType = get(rawData, 'fieldRefLookupToolType.fieldToolTypeIdentifier')
  const iconImage = get(rawData, 'fieldRefIconImage')
  const backgroundImage = get(rawData, 'fieldRefBackgroundImage')
  const fieldExtraConfigJson = get(rawData, 'fieldExtraConfigJson') ? JSON.parse(get(rawData, 'fieldExtraConfigJson')) : {}

  return {
    id: get(rawData, 'id', ''),
    slug: get(rawData, 'path.alias', ''),
    toolType,
    name: get(rawData, 'title', ''),
    description: get(rawData, 'fieldDescription', ''),
    descriptionShort: get(rawData, 'fieldShortDescription', ''),
    contactInfo: getContactEmail(rawData),
    dataSources: formatDataSources(dataSources, toolType),
    landingPageSections: [
      ...(hasTextSections ? formatTextSections(rawData.fieldLookUpToolSection) : []),
      ...(hasResources ? [formatResources(rawData.fieldLookUpToolResourceSec)] : []),
      ...(hasDatasets ? [formatDatasets(rawData.fieldLookupToolDatasetsSec)] : []),
      ...(hasFaqs ? [formatFaqs(rawData.fieldLookupToolFaqSec)] : [])
    ],
    licenseAgreement: formatLicenseAgreement(rawData.fieldRefLicenseAgreement),
    metaTags: formatMetaTags(get(rawData, 'metatagNormalized'), []),
    fieldIframeSection: get(rawData, 'fieldIframeSection', ''),
    fieldExtraConfigJson,
    iconImage,
    backgroundImage
  }
}

/**
 *
 * @param {object[]} dataSources - raw data sources from JSON API
 */
export function formatDataSources (dataSources) {
  const sources = dataSources.reduce((obj, dataSource) => {
    const dataSourceId = get(dataSource, 'fieldDataSourceIdentifier')
    return {
      ...obj,
      [dataSourceId]: dataSource
    }
  }, { default: dataSources[0] })

  const formattedDataSources = Object.keys(sources).reduce((obj, key) => {
    const rawDataSource = sources[key]
    const datasetTypePath = 'fieldPgDataSource.fieldRefDatasetType'
    const datasetVersionPath = 'fieldPgDataSource.fieldRefDatasetVersion'
    const datasetFilePath = 'fieldPgDataSource.fieldRefMediaInteractive'
    const lookUpToolPath = 'fieldPgDataSource.fieldRefLookUpTool'

    let dataSourceType
    let dataSourceUuid
    let datasetTypeName
    let updateFrequency

    if (has(rawDataSource, datasetTypePath)) {
      dataSourceType = 'datasetType'
      dataSourceUuid = get(rawDataSource, `${datasetTypePath}.id`)
      datasetTypeName = get(rawDataSource, `${datasetTypePath}.name`)
      updateFrequency = get(rawDataSource, `${datasetTypePath}.fieldUpdateFrequency`)
    } else if (has(rawDataSource, datasetVersionPath)) {
      dataSourceType = 'datasetVersion'
      dataSourceUuid = get(rawDataSource, `${datasetVersionPath}.id`)
    } else if (has(rawDataSource, lookUpToolPath)) {
      dataSourceType = 'lookUpTool'
      dataSourceUuid = get(rawDataSource, `${lookUpToolPath}.id`)
    } else {
      dataSourceType = 'dataFile'
      dataSourceUuid = get(rawDataSource, `${datasetFilePath}.id`)
    }

    return {
      ...obj,
      [key]: {
        id: dataSourceUuid,
        dataSourceType,
        dataSourceIdentifier: get(rawDataSource, 'fieldDataSourceIdentifier'),
        datasetTypeName,
        updateFrequency
      }
    }
  }, {})

  return formattedDataSources
}

/**
 * format tools for featuerd tools component on the tool detail page
 * @param {object[]} tools - JSON API deserialized tool objects
 * @returns {object[]}
 */
export function formatFeaturedTools (tools = []) {
  return tools.map(tool => {
    const toolType = get(tool, 'fieldRefLookupToolType.fieldToolTypeIdentifier')
    return {
      id: tool.id,
      url: get(tool, 'path.alias'),
      description: tool.fieldShortDescription,
      title: tool.title,
      icon: toolIcons[toolType]
    }
  })
}

/**
 * format tools for use
 * @param {object[]} tools - JSON API deserialized tool objects
 * @returns {string}
 */
export function formatToolSlug (tools = []) {
  const tool = !isEmpty(tools) ? tools[0] : null
  return tool ? get(tool, 'path.alias') : null
}

/**
 * format text sections for lookup tool landing page consumption
 * @param {object} data - fieldLookUpToolSection object from BE response
 * @returns {object[]} - unlike the other section formatters, this returns an array
 *                       of objects instead of an object
 */
export function formatTextSections (sections = []) {
  return sections.map((section) => {
    return {
      id: section.id,
      type: SECTION_TEXT,
      label: get(section, 'fieldInPageNavigationLink'),
      header: get(section, 'fieldSectionHeader'),
      content: get(section, 'fieldWysiwygParagraph.processed')
    }
  })
}

/**
 * format resources for lookup tool landing page consumption
 * @param {object} data - fieldLookUpToolResourceSec object from BE JSON API deserialized response
 * @returns {object}
 */
export function formatResources (data) {
  const additionalResources = get(data, 'fieldLookupToolAddlResources', [])
  const featuredResources = get(data, 'fieldLookupToolFeatResources', [])
  const resources = [...additionalResources, ...featuredResources]

  return {
    id: data.id,
    type: SECTION_RESOURCES,
    label: get(data, 'fieldInPageNavigationLink', 'Resources'),
    header: get(data, 'fieldSectionHeader', ''),
    eyebrow: '',
    description: get(data, 'fieldWysiwygParagraph.processed', ''),
    resources: resources.map(resource => {
      const details = extractResourceTypeData(resource, true)
      let link = 'fileUrl'
      let title = 'title'
      if (details.type === 'Related Link' || resource.fieldInternalPage) {
        link = 'internalPageSlug'
      } else if (details.type === 'External Link') {
        title = 'externalLinkLabel'
        link = 'externalLinkUrl'
      }
      return {
        isFeatured: resource.parentFieldName.indexOf('feat') > -1 || false,
        type: details.type,
        [title]: details.title,
        [link]: details.link.url,
        fileName: details.link.name,
        showOnResourcesSection: true
      }
    })
  }
}

/**
 * format dataset for lookup tool landing page consumption
 * @param {object} data - fieldLookupToolDatasetsSec object from BE JSON API deserialized response
 * @returns {object}
 */
export function formatDatasets (data) {
  const label = get(data, 'fieldInPageNavigationLink', 'Datasets')
  const header = get(data, 'fieldSectionHeader', 'Information about associated datasets.')
  const description = get(data, 'fieldWysiwygParagraph.processed')
  const datasetList = get(data, 'fieldLookupToolRelDatasets', [])

  return {
    id: data.id,
    type: SECTION_DATASETS,
    label,
    header,
    description,
    datasets: datasetList.map(dataset => {
      const heading = get(dataset, 'fieldRefDatasetType.name', '')
      const slug = get(dataset, 'fieldRefDatasetType.path.alias', '')
      const lastUpdated = get(dataset, 'fieldRefDatasetType.fieldCurrentDatasetVersion.fieldLastUpdatedDate')
      return {
        id: dataset.id,
        heading,
        slug,
        footer: dayjs(lastUpdated).format('MMMM D, YYYY')
      }
    })
  }
}

/**
 * format faqs for lookup tool landing page consumption
 * @param {object} data - fieldLookupToolFaqSec object from BE JSON API deserialized response
 * @returns {object}
 */
export function formatFaqs (data) {
  const label = get(data, 'fieldInPageNavigationLink', 'FAQs')
  const header = get(data, 'fieldSectionHeader', 'Frequently Asked Questions')
  const faqList = get(data, 'fieldLookupToolRefFaqList.fieldRefFaq', [])

  return {
    id: data.id,
    type: SECTION_FAQS,
    label,
    header,
    faqs: faqList.map(faq => ({
      fieldFaqAnswer: faq.fieldFaqAnswer,
      fieldFaqQuestion: faq.fieldFaqQuestion
    }))
  }
}

/**
 * format license agreement for lookup tool landing page consumption
 * @param {object} data - fieldRefLicenseAgreement object from BE JSON API deserialized response
 * @returns {object}
 */
export function formatLicenseAgreement (data) {
  const title = get(data, 'title')
  const body = get(data, 'body')
  const id = get(data, 'id')

  if (!title && !body && !id) return {}

  return {
    title,
    body,
    id
  }
}

/**
 *
 * @param {object} response
 * @param {array} respnose.data - list of data rows; each row represents one record (arrays)
 * @param {object} meta
 * @param {string[]} meta.headers
 * @param {function} transformFn - optional function to transform the resultant object
 *
 */
export function convertDataToObjectArray ({ data, meta }, transformFn) {
  return data.map((item) => {
    const obj = meta.headers.reduce((r, header, idx) => {
      return { ...r, [header]: item[idx] }
    }, {})

    return transformFn ? transformFn(obj) : obj
  })
}

export function getZip (zipcode = '') {
  const zip = zipcode.split('')
  return zip.length > 5
    ? `${zip.slice(0, 5).join('')}-${zip.slice(5, zip.length).join('')}`
    : zipcode
}

/**
 *
 * @param {object} rawData
 */

export function formatLookupToolDetails (rawData, toolType) {
  const { meta, data: dataRows } = rawData
  const headers = meta.headers
  const displayHeaders = Object.keys(toolDetails[toolType].detailColumns)
  const headerIndicies = []
  displayHeaders.forEach((displayHeader) => {
    return headerIndicies.push(headers.indexOf(displayHeader))
  })

  const columnFormats = displayHeaders.map(column => toolDetails[toolType].detailColumns[column].format)

  const formattedRows = dataRows.map(row => {
    return headerIndicies.map((headerIndex, index) => {
      return formatToolDetailCells(row[headerIndex], columnFormats[index])
    })
  })

  const tableFormat = {
    headers: displayHeaders.map(column => toolDetails[toolType].detailColumns[column].label),
    rows: formattedRows,
    headerClasses: displayHeaders.map(column => toolDetails[toolType].detailColumns[column].headerClasses),
    bodyClasses: displayHeaders.map(column => toolDetails[toolType].detailColumns[column].classes)
  }

  return {
    tableFormat
  }
}

/**
 *
 * @param {object} rawData
 * @param {object} rawData.meta
 * @param {object[]} rawData.data
 * @param {string} toolType
 * @param {object} additionalData - supplementary data coming from outside main api response
 */
export function formatLookupToolHero (rawData, toolType, additionalData) {
  const formattedData = convertDataToObjectArray(rawData)

  // reference first data point for hero data since all rows should have the same
  // name, ccn/npi etc.
  const referenceDataPoint = formattedData[0]

  if (!referenceDataPoint) {
    throw new Error('Empty data for lookup tool detail page')
  }

  const { title, detailMeta } = extractHeroDetails(referenceDataPoint, toolType, additionalData)

  return {
    title: title,
    detailMeta: detailMeta
  }
}

/**
 *
 * @param {object} toolsData
 * @param {string} toolType
 * @param {object} additionalData - supplementary data coming from outside main api response
 */
export function extractHeroDetails (toolsData, toolType, additionalData) {
  let detailMeta, title
  const {
    inpatient,
    prescriber,
    physician,
    revalidation
  } = toolTypes

  switch (toolType) {
    case inpatient:
      title = toolsData.Rndrng_Prvdr_Org_Name
      detailMeta = extractInpatientDetailMeta(toolsData, additionalData)
      break
    case prescriber:
      title = `${toolsData.Prscrbr_First_Name} ${toolsData.Prscrbr_Last_Org_Name}`
      detailMeta = extractPrescriberDetailMeta(toolsData, additionalData)
      break
    case physician:
      title = toolsData.Rndrng_Prvdr_Ent_Cd === 'I'
        ? `${toolsData.Rndrng_Prvdr_First_Name} ${toolsData.Rndrng_Prvdr_Last_Org_Name}`
        : toolsData.Rndrng_Prvdr_Last_Org_Name
      detailMeta = extractPhysicianDetailMeta(toolsData, additionalData)
      break
    case revalidation:
      title = getNameFromRow(toolsData)
      detailMeta = extractRevalidationDetailMeta(toolsData, additionalData)
      break
    default: {
      throw new Error(`Unknown tool type ${toolType}`)
    }
  }

  return { detailMeta, title }
}

/**
 *
 * @param {object} toolsData
 * @param {string} toolsData[REVALIDATION_DUE_DATE_COLUMN]
 * @param {string} toolsData[ENROLLMENT_ID_COLUMN]
 * @param {string} toolsData[STATE_COLUMN]
 * @param {string} toolsData[ENROLLEMENT_TYPE_COLUMN]
 * @param {string} toolsData[ENTROLLMENT_SPECIALTY_COLUMN]
 * @param {string} toolsData[NPI_LIST_COLUMN]
 * @param {object} additionalData
 * @param {string} additionalData.lastUpdated - last updated date of dataset
 */
function extractRevalidationDetailMeta (toolsData = {}, additionalData = {}) {
  const { lastUpdated } = additionalData
  const { revalidationDueDate } = getDueDateDisplay(toolsData)
  const npiList = getNpiList(toolsData)

  const NpiComponent = (
    <NpiTextDisplay
      name={getNameFromRow(toolsData)}
      currentNpi={npiList[0]}
      npis={npiList}
    />
  )

  const detailMeta = [
    { label: 'Due Date', values: [revalidationDueDate] },
    { label: 'State', values: [getState(toolsData)] },
    { label: 'NPI', component: NpiComponent },
    ...(isDmeSupplier(toolsData) ? [{ label: 'Enrollment Type', values: [isDmeSupplier(toolsData)] }] : []),
    { label: 'Specialty', values: [getSpecialty(toolsData)] },
    { label: 'Last Updated', values: [dayjs(lastUpdated).format('MM/DD/YYYY')] } // to be filled in dynamically
  ]

  return detailMeta
}

/**
 *
 * @param {object} toolsData
 * @param {string} toolsData.Rndrng_Prvdr_CCN
 * @param {string} toolsData.Rndrng_Prvdr_St
 * @param {string} toolsData.Rndrng_Prvdr_City
 * @param {string} toolsData.Rndrng_Prvdr_State_Abrvtn
 * @param {string} toolsData.Rndrng_Prvdr_Zip5
 */
function extractInpatientDetailMeta (toolsData = {}) {
  const {
    Rndrng_Prvdr_CCN: ccn,
    Rndrng_Prvdr_St: address,
    Rndrng_Prvdr_City: city,
    Rndrng_Prvdr_State_Abrvtn: state,
    Rndrng_Prvdr_Zip5: zip
  } = toolsData

  return [
    {
      label: null,
      values: [
        address,
        `${city}, ${state} ${getZip(zip)}`
      ]
    },
    { label: 'CCN', values: [ccn] }
  ]
}

/**
 *
 * @param {object} toolsData
 * @param {string} toolsData.Prscrbr_City
 * @param {string} toolsData.Prscrbr_State_Abrvtn
 * @param {string} toolsData.Prscrbr_Type
 * @param {string} toolsData.Prscrbr_NPI
 */
function extractPrescriberDetailMeta (toolsData = {}) {
  const {
    Prscrbr_City: city,
    Prscrbr_State_Abrvtn: state,
    Prscrbr_Type: specialty,
    Prscrbr_NPI: npi
  } = toolsData

  return [
    {
      label: null,
      values: [`${city}, ${state}`]
    },
    { label: 'NPI', values: [npi] },
    { label: 'Specialty', values: [specialty] }
  ]
}

/**
 *
 * @param {object} toolsData
 * @param {string} toolsData.Rndrng_Prvdr_St1
 * @param {string} toolsData.Rndrng_Prvdr_St2
 * @param {string} toolsData.Rndrng_Prvdr_City
 * @param {string} toolsData.Rndrng_Prvdr_State_Abrvtn
 * @param {string} toolsData.Rndrng_Prvdr_Zip5
 * @param {string} toolsData.Rndrng_Prvdr_Ent_Cd
 * @param {string} toolsData.Rndrng_NPI
 */
function extractPhysicianDetailMeta (toolsData = {}) {
  const {
    Rndrng_Prvdr_St1: addressLine1,
    Rndrng_Prvdr_St2: addressLine2,
    Rndrng_Prvdr_City: city,
    Rndrng_Prvdr_State_Abrvtn: state,
    Rndrng_Prvdr_Zip5: zip,
    Rndrng_Prvdr_Type: specialty,
    Rndrng_Prvdr_Ent_Cd: entityType,
    Rndrng_NPI: npi
  } = toolsData

  return [
    {
      label: null,
      values: [
        addressLine1,
        addressLine2,
        `${city}, ${state} ${getZip(zip)}`
      ]
    },
    { label: 'Specialty', values: [specialty] },
    { label: 'Entity Type', values: [entityType === 'I' ? 'Individual' : 'Organization'] },
    { label: 'NPI', values: [npi] }
  ]
}

export function formatGlobalListingToolsData (data, rawIncludedData) {
  const {
    title = '',
    fieldDescription = '',
    fieldRefToolsListSection,
    metatagNormalized = []
  } = data
  return {
    hero: { title, description: fieldDescription },
    lookupTools: formatToolsSection(fieldRefToolsListSection[0], 'lookupTools', rawIncludedData),
    mapTools: formatToolsSection(fieldRefToolsListSection[1], 'mapTools', rawIncludedData),
    visualizationTools: formatToolsSection(fieldRefToolsListSection[2], 'visualizationTools', rawIncludedData),
    metaTags: formatMetaTags(metatagNormalized)
  }
}

export function formatToolsSection (toolsSection, sectionId, rawIncludedData) {
  const { fieldSectionHeader, fieldRefGlobalTool } = toolsSection
  return {
    [`${sectionId}Header`]: fieldSectionHeader,
    [sectionId]: fieldRefGlobalTool.map(tool => {
      const visualization = get(tool, 'fieldToolsVisualizationUrl')
      const internalPage = get(tool, 'fieldInternalPage')
      const toolType = get(tool, 'fieldInternalPage.fieldRefLookupToolType.fieldToolTypeIdentifier')
      const iFrameSection = get(tool, 'fieldIframeSection')
      let section
      if (internalPage) {
        // remove icon hardcoding once internal tools pages have icon data
        // the hardcoding remains for now for fallback and feature flag
        const mediaImage1 = tool?.fieldInternalPage?.fieldRefIconImage?.fieldMediaImage1
        const icon = mediaImage1?.uri?.url
        const altText = rawIncludedData?.find(i => i?.relationships?.field_media_image_1?.data?.id === mediaImage1?.id)?.relationships?.field_media_image_1?.data?.meta?.alt

        const title = get(internalPage, 'title')
        section = {
          url: get(internalPage, 'path.alias'),
          description: get(internalPage, 'fieldShortDescription'),
          title: title,
          icon: icon ? apiSite + icon : toolIcons[toolType],
          iFrameSection: iFrameSection,
          altText: altText
        }
      } else if (visualization) {
        section = {
          tools: visualization.map(viz => {
            return { label: get(viz, 'fieldCallToActionText'), url: get(viz, 'fieldExternalLink.uri') }
          }),
          description: get(tool, 'fieldWysiwygParagraph.processed'),
          title: get(tool, 'fieldComponentTitle')
        }
      } else {
        section = {
          url: get(tool, 'fieldExternalLink.uri'),
          description: get(tool, 'fieldWysiwygParagraph.processed'),
          title: get(tool, 'fieldComponentTitle'),
          icon: `${getAssetOrigin()}${get(tool, 'fieldMediaImage.fieldMediaImage1.uri.url')}`,
          iFrameSection: iFrameSection
        }
      }
      return section
    })
  }
}
