import React, { useEffect, useState } from 'react'
import compose from '../../../hocs/compose'
import withBoundary from '../../../hocs/withBoundary/withBoundary'
import { ReactComponent as Caret } from '../../../assets/caret.svg'
import { getWhatsNewSettings, getWhatsNewEntries } from '../../../services/apiDomains/whatsNew'
import dayjs from 'dayjs'

import './HomePageWhatsNew.scss'
import RichTextField from '../../common/RichTextField/RichTextField'

const HomePageWhatsNew = (props) => {
  const WHATS_NEW_ENTRY_LIMIT = 5
  const [whatsNewSettings, setWhatsNewSettings] = useState(null)
  const [whatsNewList, setWhatsNewList] = useState(null)
  const [spotlight, setSpotlight] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      if (!whatsNewSettings) {
        const newSettings = await getWhatsNewSettings()
        setWhatsNewSettings(newSettings)
        const whatsNewListData = await getWhatsNewEntries(WHATS_NEW_ENTRY_LIMIT)
        // Expand the first entry
        if (whatsNewListData?.length > 0) {
          whatsNewListData[0] = { ...whatsNewListData[0], expanded: true }
        }

        const spotLightForRightSide = whatsNewListData?.find((e) => e?.fieldWhatsnewSpotlight)
        const sliceOffset = spotLightForRightSide ? 1 : 0
        setSpotlight(spotLightForRightSide)
        setWhatsNewList(whatsNewListData.slice(sliceOffset, 4 + sliceOffset))
      }
    }
    fetchData()
  }, [])

  const isExpanded = (section) => {
    return whatsNewList?.find(s => s?.id === section?.id && s.expanded === true)
  }

  const expandSection = (section) => {
    const alreadyExpanded = isExpanded(section)
    const updatedSections = whatsNewList?.map((s) => {
      return {
        ...s,
        expanded: alreadyExpanded ? false : (s.id === section?.id)
      }
    })
    setWhatsNewList(updatedSections)
  }

  const renderLink = (entry) => {
    switch (entry?.fieldWhatsnewUpdateOption) {
      case 'internal_link':
        return (entry?.fieldRefWhatsnewInternalLink?.path?.alias &&
          <a href={entry?.fieldRefWhatsnewInternalLink?.path?.alias} target='_self'>{entry?.fieldRefWhatsnewLinkLabel?.name}</a>)

      case 'external_link':
        return (entry?.fieldWhatsnewExternalLink?.uri &&
          <a href={entry?.fieldWhatsnewExternalLink?.uri} target='_blank' rel='noreferrer'>{entry?.fieldRefWhatsnewLinkLabel?.name}</a>)

      default:
        return <></>
    }
  }

  return (
    <div className='WhatsNewPage'>
      <div className='MainContainer'>
        <div className='TitleSection'>
          <div className='Title'>What's new</div>
          <div className='Subtitle'>See the latest updates on Data.CMS.gov</div>
        </div>

        <div className='MainSection'>
          <div className='LeftColumn'>
            <div className='AccordionGroup'>
              <div className='Accordion'>
                <div className='AccordionContent'>

                  {whatsNewList?.map((entry) => (
                    <div className='AccordionCard' key={entry?.id}>
                      <div className='UpdateContent'>
                        <div className='UpdateContentLeft'>
                          <div className='UpdateTitle'>{entry?.fieldRefWhatsnewUpdateType?.name}</div>
                          <div className='UpdateLink'>{entry?.title}</div>
                        </div>
                        {isExpanded(entry) &&
                          <div className={`ExpandedContent ${isExpanded(entry) ? 'show' : ''}`}>
                            <RichTextField content={entry?.fieldWhatsnewDescription?.value} />
                            <div className='ExpandedContentDate'>{dayjs(entry?.fieldWhatsnewDate).format('MMMM D, YYYY')}</div>
                            {entry?.fieldWhatsnewUpdateOption !== 'no_link' && <div className='ExpandedContentLink'>{renderLink(entry)}</div>}

                          </div>}
                      </div>
                      <div className='ExpandCollapse'>
                        <button className={isExpanded(entry) ? '' : 'flipHorizontal'} onClick={() => { expandSection(entry) }}>
                          <Caret />
                        </button>
                      </div>

                    </div>
                  ))}
                </div>
              </div>

            </div>
          </div>
          <div className='FeaturedCard'>
            <div className='CardImages'>
              {spotlight && (
                <>
                  <img src={whatsNewSettings?.spotLightUrl} alt='Spotlight Image' />
                  <div className='SpotLightTitle'>SPOTLIGHT</div>
                </>
              )}
              {!spotlight && <img src={whatsNewSettings?.stayTunedUrl} alt='Stay Tuned Image' />}

            </div>

            {spotlight && (
              <div className='FeaturedCard__BottomArea'>
                <div className='NewFeatureText'>
                  <div className='NewFeatureText__StaticTitle'>{spotlight?.fieldRefWhatsnewUpdateType?.name}</div>
                  <div className='NewFeatureText__Title'>{spotlight?.title}</div>
                  <div className='NewFeatureText__Body'>
                    <RichTextField content={spotlight?.fieldWhatsnewDescription?.value} />
                  </div>
                  <div className='NewFeatureText__Date'>
                    {dayjs(spotlight?.fieldWhatsnewDate).format('MMMM D, YYYY')}
                  </div>
                </div>
                <div className='NewFeatureLink'>
                  {spotlight?.fieldWhatsnewUpdateOption !== 'no_link' && <div className='ExpandedContentLink'>{renderLink(spotlight)}</div>}
                </div>
              </div>)}

            {!spotlight && (
              <div className='StayTuned'>
                <div className='StayTunedTitle'>Stay Tuned</div>
                <div className='StayTunedDescription'>Check back soon for the latest updates.</div>
              </div>
            )}

          </div>
        </div>
      </div>
    </div>
  )
}

export default compose(withBoundary)(HomePageWhatsNew)
