import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'

import DownloadModalSelection from './DownloadModalSelection'
import DownloadModalCustomized from './DownloadModalCustomized'
import {
  getNewestDatasetVersion,
  convertLocalToUTCDate,
  getTooltipBasedOnFrequency,
  getEndDateForFrequencyPeriod
} from '../../../utilities/datasetHelpers'
import { getFeatureSetting } from '../../../stores/siteStore/siteStore'
import { prepareZip } from '../../../components/common/DownloadProgressBar/DownloadProgressBar'
import { logDatasetDownload } from '../../../services/api'
import { getDatasetVersionResources } from '../../../services/apiDomains/dataset'
import {
  getResourcesByType,
  getResourcesInDateRange,
  getVersionIdsInRange,
  getResourcesFromNodeSelection,
  downloadResourceFiles,
  downloadFile,
  orderCategories,
  orderCategoriesFromArray,
  getDatasetDateBoundry,
  sortResourcesByVersionDate,
  SelectedOptionTypes,
  getFileSizeSumFromResources,
  EXCLUDED_TYPES
} from './downloadModalHelpers'
import { trackDownloadModalClick } from '../../../utilities/analyticsEventHelpers'
import { ReactComponent as DownloadWithLineIcon } from '../../../assets/fontawesome/custom/download-icon.svg'
import { ReactComponent as InfoIcon } from '../../../assets/fontawesome/custom/Circle-Info-Outline.svg'
import { ReactComponent as InfoIconFilledIn } from '../../../assets/fontawesome/custom/circle-info-filled-in.svg'
import { ReactComponent as ChevronRightSmall } from '../../../assets/fontawesome/custom/chevron-right-24.svg'
import { ReactComponent as ChevronLeft } from '../../../assets/fontawesome/custom/chevron-left.svg'
import { ReactComponent as XIcon } from '../../../assets/fontawesome/custom/x-icon.svg'

import './DownloadModal.scss'
import DownloadModalReview from './DownloadModalReview'

const DownloadModalStep = {
  SelectionStep: 0,
  CustomizeStep: 1,
  ReviewStep: 2
}

const DownloadModal = (props) => {
  const {
    show,
    onHide,
    versions,
    downloadResources,
    datasetTypeUuid,
    datasetFrequency,
    datasetName
  } = props

  const [selectedOption, setSelectedOption] = useState(SelectedOptionTypes.Option1)
  const [step, setStep] = useState(DownloadModalStep.SelectionStep)
  const [customizedOptions, setSelectedCustomizedOptions] = useState(null)
  const [selectedStartDate, setSelectedStartDate] = useState(null)
  const [selectedEndDate, setSelectedEndDate] = useState(null)
  const [totalFileSize, setTotalFileSize] = useState(null)
  const [totalFilesSelected, setTotalFilesSelected] = useState(null)
  const [licenseFile, setLicenseFile] = useState(null)
  const [checkboxesDirty, setCheckboxesDirty] = useState(false)
  const [newestVersionResources, setNewestVersionResources] = useState(null)

  useEffect(() => {
    const getData = async () => {
      setNewestVersionResources(await getDatasetVersionResources(getNewestDatasetVersion(versions)?.id))
    }
    if (versions && versions?.length > 0) {
      getData()
    }
  }, [versions])

  useEffect(() => {
    const downloadZip = getFeatureSetting('downloadModalV2', 'downloadZip')
    if (downloadZip) {
      const licenseFile = downloadResources?.find(r => r.type === 'License Agreement')
      if (licenseFile) {
        setLicenseFile(licenseFile)
      }
    }
  }, [downloadResources])

  const shouldDisableReview = () => {
    const checkedNodes = customizedOptions?.flatMap(parent => parent.children.filter(child => child.checked === true))
    return step === DownloadModalStep.CustomizeStep && (!customizedOptions || checkedNodes.length === 0)
  }

  const selectedOptionChanged = (option) => {
    setSelectedOption(option)
  }

  const itemsUpdated = (items, fileSize, filesSelected) => {
    setSelectedCustomizedOptions(items)
    setTotalFileSize(fileSize)
    setTotalFilesSelected(filesSelected)
  }

  const datesUpdated = (startDate, endDate) => {
    setSelectedStartDate(startDate)
    endDate = endDate !== null ? getEndDateForFrequencyPeriod(endDate, datasetFrequency) : endDate
    setSelectedEndDate(endDate)
  }
  const handleBackClick = () => {
    setStep(step - 1)
  }

  const handleDownloadClick = async () => {
    onHide()
    const downloadZip = getFeatureSetting('downloadModalV2', 'downloadZip')
    const isCustomizedDownload = selectedOption === SelectedOptionTypes.Option3
    const onlyDownloadDataset = selectedOption === SelectedOptionTypes.Option2

    const resourcesToDownload = isCustomizedDownload
      ? getResourcesFromNodeSelection(resourcesByType, customizedOptions)
      : newestVersionResources

    if (downloadZip) {
      const datasetIds = isCustomizedDownload
        ? getVersionIdsInRange(versions, selectedStartDateUTC, selectedEndDateUTC)?.join(',')
        : getNewestDatasetVersion(versions).id
      const fileIds = onlyDownloadDataset
        ? resourcesToDownload?.filter(r => r.type === 'Primary')[0]?.fileUuid
        : resourcesToDownload?.map(r => EXCLUDED_TYPES.includes(r?.type) || !r ? null : r?.fileUuid)?.filter(s => s !== null)?.join(',')

      // Download license file if it's pending
      if (licenseFile) {
        downloadFile(licenseFile?.fileUrl, {
          onLoadStart: () => {},
          onLoadSuccess: () => {},
          onLoadError: (e) => { console.error('Error downloading license file', e) },
          fileName: licenseFile?.fileName
        })
        setLicenseFile(null)
      }
      // Call the API to initiate download request
      await prepareZip(datasetName, datasetTypeUuid, datasetIds, fileIds, selectedOption, totalFileSize, true)
    } else {
      // Download the individual files
      downloadResourceFiles(resourcesToDownload, isCustomizedDownload ? onlyDownloadDataset : false)
    }
    const fileSizeSum = selectedOption.Option3 ? totalFileSize : getFileSizeSumFromResources(resourcesToDownload)
    const commaSeparatedFileList = onlyDownloadDataset ? resourcesToDownload?.filter(r => r.type === 'Primary')[0]?.title
      : resourcesToDownload.filter((r) => !EXCLUDED_TYPES.includes(r?.type)).reduce((a, b) => a + (a ? ', ' : '') + b?.title, '')
    const analyticsInformation = {
      fileName: datasetName,
      fileSize: fileSizeSum,
      fileMime: 'application/zip',
      datasetVersionLabel: '',
      datasetName: datasetName,
      frequency: datasetFrequency,
      startDate: selectedStartDate?.toISOString()?.split('T')[0],
      endDate: selectedEndDate?.toISOString()?.split('T')[0],
      selectedOption: selectedOption
    }
    trackDownloadModalClick(analyticsInformation, commaSeparatedFileList, window.utag)
    logDatasetDownload(datasetTypeUuid)
  }
  const newestVersion = getNewestDatasetVersion(versions)
  const relevantResources = sortResourcesByVersionDate(downloadResources)
  let resourcesByTypeUnfiltered = getResourcesByType(relevantResources)
  const hasSingleVersionOnly = resourcesByTypeUnfiltered?.Primary?.length === 1
  const maxGBPriorToWarning = getFeatureSetting('downloadModalV2', 'maxGBPriorToWarning')
  const useDisplayName = getFeatureSetting('downloadModalV2', 'useDisplayName')

  let selectedStartDateUTC = selectedStartDate
  let selectedEndDateUTC = selectedEndDate
  if (selectedStartDate && selectedEndDate) {
    selectedStartDateUTC = convertLocalToUTCDate(selectedStartDate)
    selectedEndDateUTC = convertLocalToUTCDate(selectedEndDate)
  }
  let resourcesByType = getResourcesByType(getResourcesInDateRange(
    relevantResources,
    selectedStartDateUTC,
    getEndDateForFrequencyPeriod(selectedEndDateUTC, datasetFrequency)
  ))
  const versionBoundry = getDatasetDateBoundry(downloadResources)

  resourcesByType = orderCategories(resourcesByType)
  resourcesByTypeUnfiltered = orderCategories(resourcesByTypeUnfiltered)
  const OrderedCategories = orderCategoriesFromArray(newestVersionResources)

  return (newestVersionResources &&
    <Modal
      className='DownloadModal'
      size='xl'
      show={show}
      onHide={onHide}
      centered
    >
      <div className='CloseSection'>
        <button onClick={onHide}>
          <XIcon />
        </button>
      </div>
      <Modal.Header className='DownloadModal__modal-header'>
        <Modal.Title>
          <div className='HeaderSection'>
            <div className='TitleSection'>
              <div className='DownloadTitle'>Download</div>
            </div>
            <div className='BottomHeaderSection'>
              <div className='DownloadSubtitle'>{datasetName}</div>
              <div className='DownloadFrequency'>
                <div>Data update frequency:&nbsp;</div>
                <div>{datasetFrequency}</div>
                <div className='Tooltip'>{getTooltipBasedOnFrequency(datasetFrequency)}</div>
              </div>
            </div>

          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='DownloadModal__modal-body'>
        {step === DownloadModalStep.SelectionStep && OrderedCategories &&
          <DownloadModalSelection selectedOptionChanged={selectedOptionChanged} selectedOption={selectedOption} resourcesByType={resourcesByTypeUnfiltered} resourcesForLatestVersion={OrderedCategories} hasSingleDataset={hasSingleVersionOnly} newestVersion={newestVersion} frequency={datasetFrequency} />}
        {step === DownloadModalStep.CustomizeStep &&
          <DownloadModalCustomized itemsUpdated={itemsUpdated} customizedOptions={customizedOptions} datesUpdated={datesUpdated} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} resourcesByType={resourcesByType} datasetFrequency={datasetFrequency} hasSingleDataset={hasSingleVersionOnly} versionBoundry={versionBoundry} maxGB={maxGBPriorToWarning} useDisplayName={useDisplayName} areCheckboxesDirty={checkboxesDirty} setCheckboxesDirty={setCheckboxesDirty} />}
        {step === DownloadModalStep.ReviewStep &&
          <DownloadModalReview customizedOptions={customizedOptions} totalFileSize={totalFileSize} totalFilesSelected={totalFilesSelected} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} hasSingleDataset={hasSingleVersionOnly} resourcesByType={resourcesByType} datasetFrequency={datasetFrequency} maxGB={maxGBPriorToWarning} goBackHandler={handleBackClick} />}
      </Modal.Body>
      <Modal.Footer className='DownloadModal__modal-footer'>
        {step === DownloadModalStep.SelectionStep &&
          <div className='FooterText'>
            <div>
              <div>
                <InfoIcon />
              </div>
              {hasSingleVersionOnly
                ? 'You can download the latest data and/or all supporting resources OR individual files by type. Use this data offline in other applications. Use of a database or statistical software may be required for large files.'
                : 'You can download the latest data and/or all supporting resources OR individual files by date range. Use this data offline in other applications. Use of a database or statistical software may be required for large files.'}

            </div>
          </div>}

        <div className={'ButtonSection ' + (selectedOption === SelectedOptionTypes.Option3 ? 'MultiButton' : 'SingleButton')}>

          {selectedOption !== SelectedOptionTypes.Option1 &&
          (step !== DownloadModalStep.SelectionStep) && (
            <button type='button' className='left-btn' onClick={handleBackClick}>
              <div className='BackButtonContent'>
                <div>
                  <ChevronLeft />
                </div>
                <div className='button-text'>Back</div>
              </div>
            </button>
          )}

          {(step === DownloadModalStep.CustomizeStep || step === DownloadModalStep.ReviewStep) && (
            <div className='HowToAccess'>
              <InfoIcon className='InfoIcon' />
              <InfoIconFilledIn className='InfoIconFilledIn' />
              <span>How to access this data</span>
              <div className='Tooltip'>Use this data offline in other applications. Use of a database
                <br /> or statistical software may be required for large files.
              </div>
            </div>
          )}

          {selectedOption !== SelectedOptionTypes.Option3 && (
            <button type='button' className='btn btn-primary btn-lg' onClick={handleDownloadClick}>
              <div className='ActionButtonContent'>
                <div className='DownloadIcon'>
                  <DownloadWithLineIcon />
                </div>
                <div
                  className='button-text'
                >Download Files
                </div>
              </div>
            </button>
          )}
          {selectedOption === SelectedOptionTypes.Option3 &&
          (step === DownloadModalStep.SelectionStep || step === DownloadModalStep.CustomizeStep) && (
            <button type='button' {...(shouldDisableReview() ? { disabled: true } : {})} className='btn btn-primary btn-lg right-btn' onClick={() => { setStep(step + 1) }}>
              <div className='ActionButtonContent'>
                <div className='button-text'>{step === DownloadModalStep.CustomizeStep ? 'Review' : 'Continue'}</div>
                <div>
                  <ChevronRightSmall />
                </div>
              </div>
            </button>
          )}

          {(step === DownloadModalStep.ReviewStep) && (
            <button type='button' className='btn btn-primary btn-lg right-btn' onClick={handleDownloadClick}>
              <div className='ActionButtonContent'>
                <div className='DownloadIcon'>
                  <DownloadWithLineIcon />
                </div>
                <div
                  className='button-text'
                >Download Files
                </div>
              </div>
            </button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  )
}

DownloadModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  versions: PropTypes.array,
  downloadResources: PropTypes.array,
  datasetTypeUuid: PropTypes.string,
  datasetFrequency: PropTypes.string,
  datasetName: PropTypes.string
}

export default DownloadModal
