import groupBy from 'lodash/groupBy'
import toNumber from 'lodash/toNumber'
import uniqBy from 'lodash/uniqBy'
import path from 'path-browserify'
import datasetVersionFormatter from '../../../utilities/datasetVersionFormatter'
import {
  getSortedDatasetVersions,
  getDatasetVersionValue
} from '../../../utilities/datasetHelpers'
import { updateDownloadStore } from '../../../stores/downloadIndicatorStore'
export const COMPRESSION_RATIO = 1
export const EXCLUDED_TYPES = ['External Link', 'License Agreement']
export const SelectedOptionTypes = {
  Option1: 'latest_dataset_resources',
  Option2: 'latest_dataset',
  Option3: 'custom'
}

const ADDITIONAL_RESOURCES_STRING = 'Additional Resources'
const CATEGORY_ORDERING = ['Primary', 'Methodology', 'Data Dictionary', 'Glossary']

/**
 * Get a duplicate-free list of resources based on their files
 * @param {object[]} resources
 */
function removeDuplicateFileResources (resources) {
  return uniqBy(resources, 'fileUuid')
}

function getResourcesByType (resources) {
  const grouped = groupBy(resources, resource => resource.type)

  // ensure unique files per file type
  Object.keys(grouped).forEach(key => {
    grouped[key] = removeDuplicateFileResources(grouped[key])
  })

  return grouped
}

function getResourceFileSizeByType (resources) {
  const resourcesByType = getResourcesByType(resources)

  const resourceFileSizes = {}

  Object.keys(resourcesByType).forEach(resourceType => {
    resourceFileSizes[resourceType] = resourcesByType[resourceType].reduce((sum, resource) => {
      const fileSizeBytesWithCompressionRatio = COMPRESSION_RATIO * resource.fileSize
      return fileSizeBytesWithCompressionRatio + sum
    }, 0)
  })

  return resourceFileSizes
}

function wait () {
  return new Promise(resolve => setTimeout(() => resolve(), 1))
}

function formatBytes (bytes) {
  bytes = toNumber(bytes) // see this come in as a string sometimes
  const k = 1000
  const doNotRenderBytes = true
  if (doNotRenderBytes && bytes < k) return '0KB'
  if (!doNotRenderBytes && bytes === 0) return '0BYTES'
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(0)) + sizes[i]
}

// grab the toUpper file extension from a file
function getFileExtension (file) {
  if (!file) { return null }

  const extension = path.extname(file).split('.')[1].toUpperCase()

  return extension
}

// convert raw data to select list format for all versions
function buildVersionsSelectList (versions = [], datasetFrequency, sort = 'desc') {
  const sortedVersions = getSortedDatasetVersions(versions, sort)

  return sortedVersions.map(version => {
    const year = datasetVersionFormatter(datasetFrequency, version.fieldDatasetVersion)

    return {
      label: year,
      value: year,
      id: version.id,
      versionObj: version
    }
  })
}

function getResourcesForOneVersion (resources, version) {
  if (!resources) { return null }

  const relevantVersionDate = getDatasetVersionValue(version)

  if (version?.fieldReReleaseSelect) {
    // Version is ammended or off cycle, so reduce to take the latest of the two
    return resources
      .filter(resource => resource.datasetVersionDate === relevantVersionDate)
      .reduce((acc, current) => {
        const existingResourceIndex = acc.findIndex(resource => resource.type === current.type)

        // If exists, replace it with the current resource, otherwise add current
        if (existingResourceIndex >= 0) {
          acc[existingResourceIndex] = current
        } else {
          acc.push(current)
        }

        return acc
      }, [])
  } else {
    return resources.filter(resource => {
      // if we are only looking to grab the latest or current (ie, passed-in) version's resources,
      // we can match the date strings
      return resource.datasetVersionDate === relevantVersionDate
    })
  }
}

function getResourcesInRangeOfVersions (resources, fromVersion, toVersion) {
  const fromVersionDateTime = new Date(getDatasetVersionValue(fromVersion)).getTime()
  const toVersionDateTime = new Date(getDatasetVersionValue(toVersion)).getTime()

  return getResourcesInDateRange(resources, fromVersionDateTime, toVersionDateTime)
}

function getResourcesInDateRange (resources, fromDate, toDate) {
  if (!resources || Object.keys(resources).length === 0 || (!fromDate && !toDate)) { return resources }

  return resources.filter(resource => {
    const resourceVersionDateTime = new Date(resource.datasetVersionDate).getTime()

    return (
      resourceVersionDateTime >= fromDate &&
      resourceVersionDateTime <= toDate
    )
  })
}

function getVersionIdsInRange (versions, fromDate, toDate) {
  if (!versions || (!fromDate && !toDate)) { return versions }

  return versions?.filter(version => {
    const versionDateTime = new Date(version.fieldDatasetVersion).getTime()

    return (
      versionDateTime >= fromDate &&
      versionDateTime <= toDate
    )
  })?.map(v => v.id)
}

function downloadWithBrowser (url) {
  const link = document.createElement('a')
  link.href = url
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

function downloadFile (fileUrl, opts) {
  const {
    onLoadStart = () => {},
    onLoadSuccess = () => {},
    onLoadProgress = () => {},
    onLoadError = () => {},
    fileName
  } = opts
  const downloadRequest = new window.XMLHttpRequest()
  downloadRequest.open('GET', fileUrl, true)
  downloadRequest.responseType = 'blob'

  downloadRequest.onloadstart = (_e) => {
    onLoadStart()
  }

  downloadRequest.onload = (_e) => {
    const downloadAttributeSupport = 'download' in document.createElement('a')
    const msSaveBlob = typeof window.navigator.msSaveBlob !== 'undefined'

    if (!downloadAttributeSupport && msSaveBlob) {
      window.navigator.msSaveBlob(downloadRequest.response, fileName)
    } else {
      var blob = downloadRequest.response
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = fileName
      link.click()
    }

    onLoadSuccess()
  }

  downloadRequest.onprogress = (e) => {
    onLoadProgress(e)
  }

  downloadRequest.onerror = (_e) => {
    onLoadError(_e)
  }

  downloadRequest.send()

  return downloadRequest
}

/**
 * Enforces a URL max length by removing query params.
 * @param {obj} urls
 * @param {string} urls.csv
 * @param {string} urls.csvExcel
 * @param {int} maxUrlParamsLength
 */
function getTrimmedDownIdsExportUrls (urls, maxURLParamsLength) {
  // Construct a URL object based on arbitrary supplied URL.
  const urlObj = new window.URL(urls.csv)
  // Remove _format parameter if any.
  urlObj.searchParams.delete('_format')
  // Calculate maxLength without including _format parameter.
  const maxLength = maxURLParamsLength - '&_format=csv_for_excel'.length
  // Calculate length of parameters in supplied urls (excluding _format).
  const paramsLength = urlObj.searchParams.toString().length
  // Default object to be returned if no changes are made.
  const returnObj = {
    csv: urls.csv,
    csvExcel: urls.csvExcel,
    message: ''
  }

  // If parameters length is below limit, no changes are needed.
  if (paramsLength <= maxLength) {
    return returnObj
  }

  const columnsLength = getParamsLengthFromUrlObj(urlObj, 'column')
  const paramsWithoutColumns = paramsLength - columnsLength
  const filtersLength = getParamsLengthFromUrlObj(urlObj, 'filter')
  const paramsWithoutFilters = paramsLength - filtersLength
  const columnsOrFiltersLength = columnsLength + filtersLength
  const paramsWithoutColumnsOrFilters = paramsLength - columnsOrFiltersLength

  // If parameters length exceeds the limit, go through the below scenarios:
  if ((columnsLength > maxLength) && (paramsWithoutColumns <= maxLength)) {
  // S1: Only column params exceed the limit.
    removeQueryParamFromUrlObj(urlObj, 'column')
    urlObj.searchParams.set('_format', 'csv')
    returnObj.csv = urlObj.toString()
    urlObj.searchParams.set('_format', 'csv_for_excel')
    returnObj.csvExcel = urlObj.toString()
    returnObj.message = 'Your column adjustments exceed the supported limit, all columns will be exported. Filters and keyword searches will still be applied.'
  } else if ((filtersLength > maxLength) && (paramsWithoutFilters <= maxLength)) {
  // S2: Only filter params exceed the limit.
    removeQueryParamFromUrlObj(urlObj, 'filter')
    urlObj.searchParams.set('_format', 'csv')
    returnObj.csv = urlObj.toString()
    urlObj.searchParams.set('_format', 'csv_for_excel')
    returnObj.csvExcel = urlObj.toString()
    returnObj.message = 'Your filters will not by applied as they exceed the supported limit. Column adjustments and keyword searches will still be applied.'
  } else if ((columnsOrFiltersLength > maxLength) && (paramsWithoutColumnsOrFilters <= maxLength)) {
  // S3: Both column and filter params exceed the limit.
    removeQueryParamFromUrlObj(urlObj, 'column')
    removeQueryParamFromUrlObj(urlObj, 'filter')
    urlObj.searchParams.set('_format', 'csv')
    returnObj.csv = urlObj.toString()
    urlObj.searchParams.set('_format', 'csv_for_excel')
    returnObj.csvExcel = urlObj.toString()
    returnObj.message = 'Your column adjustments and filters will not be applied as they exceed the limit. Keyword searches will still be applied.'
  } else {
  // Other: No adjustments will be made.
    urlObj.search = ''
    urlObj.searchParams.set('_format', 'csv')
    returnObj.csv = urlObj.toString()
    urlObj.searchParams.set('_format', 'csv_for_excel')
    returnObj.csvExcel = urlObj.toString()
    returnObj.message = 'None of your adjustments will be applied as they exceed the limit. All of the data will be downloaded.'
  }
  return returnObj
}

/**
 * Removes a given query parameter from a given URL object.
 * @param {object} urlObj A given URL object.
 * @param {string} param Query parameter to be removed.
 * @returns {object} Mutated URL object.
 */
function removeQueryParamFromUrlObj (urlObj, param) {
  if (param !== 'filter') {
    urlObj.searchParams.delete(param)
    return urlObj
  }
  const filterParams = []
  for (const key of urlObj.searchParams.keys()) {
    if (key.startsWith('filter[')) {
      filterParams.push(key)
    }
  }
  filterParams.forEach(item => {
    urlObj.searchParams.delete(item)
  })

  return urlObj
}

/**
 * Calculate the length of a given query parameter.
 * @param {object} urlObj
 * @param {string} param
 * @returns {int} The params length.
 */
function getParamsLengthFromUrlObj (urlObj, param) {
  // Clone urlObj to avoid mutation.
  const newUrlObj = new window.URL(urlObj.toString())
  const total = newUrlObj.searchParams.toString().length
  removeQueryParamFromUrlObj(newUrlObj, param)
  const totalMinusParam = newUrlObj.searchParams.toString().length
  return total - totalMinusParam
}

const formatResourcesIntoNodes = (resourcesByType, useDisplayName) => {
  if (!resourcesByType) { return null }
  const parentNodes = []

  const keys = Object.keys(resourcesByType)
  for (let i = 0; i < keys.length; i++) {
    // Skip excluded types as they cannot be downloaded
    if (EXCLUDED_TYPES.includes(keys[i])) { continue }

    const categoryName = modifyIfAdditionalResource(keys[i])
    const existingParentNode = parentNodes.find(node => node.label === categoryName)
    if (existingParentNode) {
      // Found parent notde already, combine to this existing node (e.g. Additional Resources)
      const childResources = formatChildrenResourcesIntoNodes(resourcesByType[keys[i]], useDisplayName)
      existingParentNode.children = [...existingParentNode?.children, ...childResources.filter(child => !existingParentNode.children.some(existingChild => existingChild.value === child.value))]
    } else {
      // Adding a new parent node
      parentNodes.push({ label: keys[i] === 'Primary' ? 'Datasets' : categoryName, value: keys[i], children: formatChildrenResourcesIntoNodes(resourcesByType[keys[i]], useDisplayName) })
    }
  }

  return parentNodes
}
const rollUpAdditionalResources = (resources) => {
  if (!resources) { return resources }
  const returnedResources = {}
  const keys = Object.keys(resources)
  for (let i = 0; i < keys.length; i++) {
    const oldKey = keys[i]
    const newKey = modifyIfAdditionalResource(oldKey)
    const resourceList = resources[oldKey]
    const resourceType = modifyIfAdditionalResource(newKey)
    if (resourceType === ADDITIONAL_RESOURCES_STRING) {
      if (returnedResources && returnedResources[ADDITIONAL_RESOURCES_STRING]) {
        returnedResources[ADDITIONAL_RESOURCES_STRING] = [
          ...returnedResources[ADDITIONAL_RESOURCES_STRING],
          ...resourceList.filter((resource) => {
            // Check if the current resource's fileUuid already exists in the array (e.g. additional resources might)
            return !returnedResources[ADDITIONAL_RESOURCES_STRING].some(
              (existingResource) => existingResource.fileUuid === resource.fileUuid
            )
          })
        ]
      } else {
        returnedResources[ADDITIONAL_RESOURCES_STRING] = [...resourceList]
      }
    } else {
      returnedResources[resourceType] = resources[resourceType]
    }
  }

  return returnedResources
}

const modifyIfAdditionalResource = (resourceName) => {
  switch (resourceName?.toLowerCase()) {
    case 'primary':
    case 'data dictionary':
    case 'methodology':
    case 'glossary':
      return resourceName

    case '':
    default:
      return ADDITIONAL_RESOURCES_STRING
  }
}

const formatChildrenResourcesIntoNodes = (childrenResources, useDisplayName) => {
  if (!childrenResources) { return null }

  const childNodes = []

  for (let i = 0; i < childrenResources.length; i++) {
    const resource = childrenResources[i]
    const displayName = useDisplayName ? getDisplayNameFromResource(resource) : resource?.title
    childNodes.push({ value: resource.fileUuid, label: displayName, fileType: getFileExtension(resource.fileName), fileSize: +resource.fileSize })
  }
  return childNodes
}

const getDisplayNameFromResource = (resource) => {
  if (resource?.mediaBundle === 'ancillary_file') {
    return resource?.downloadDisplayName || resource?.title
  }
  return resource?.title
}

const getResourcesFromNodeSelection = (resources, nodes) => {
  if (!resources) { return [] }
  if (!nodes || nodes?.length === 0) { return [] }

  let resourcesArray = []
  Object.keys(resources).forEach(key => {
    resourcesArray = [...resourcesArray, ...resources[key]]
  })

  let checkedNodes = []
  for (let i = 0; i < nodes.length; i++) {
    const parentNode = nodes[i]
    const validCheckedChildren = parentNode?.children?.filter((childNode) => { return childNode?.checked && childNode.value })
    if (validCheckedChildren?.length > 0) { checkedNodes = [...checkedNodes, ...validCheckedChildren] }
  }

  const checkedNodeValues = checkedNodes.map(node => node.value)
  return resourcesArray.filter(resource => checkedNodeValues.includes(resource.fileUuid))
}

const orderCategories = (resources) => {
  const orderedResources = {}
  CATEGORY_ORDERING.forEach(key => {
    if (resources?.hasOwnProperty(key)) {
      orderedResources[key] = resources[key]
      delete resources[key]
    }
  })

  Object.assign(orderedResources, resources)

  return orderedResources
}

const orderCategoriesFromArray = (resourceArray) => {
  if (!resourceArray) { return resourceArray }
  const orderedResources = resourceArray.sort((a, b) => {
    const indexA = CATEGORY_ORDERING.indexOf(a.type)
    const indexB = CATEGORY_ORDERING.indexOf(b.type)
    return indexA - indexB
  })

  return orderedResources
}

const downloadResourceFiles = async (resourcesToDownload, dataSetOnly = false) => {
  resourcesToDownload?.forEach(async (resource, index) => {
    // Ensure there is a file name and file URL
    if (!resource.fileName && !resource.fileUrl) {
      return
    }

    if (dataSetOnly && resource?.type !== 'Primary') {
      return
    }
    await wait()
    downloadFile(resource.fileUrl, {
      fileName: resource.fileName,
      onLoadSuccess: () => {
        updateDownloadStore(resource.fileUrl, { active: true, status: 'completed' })
      },
      onLoadProgress: (e) => {
        updateDownloadStore(resource.fileUrl, { active: true, status: 'preparing' })
      },
      onLoadError: () => {
        console.log('Download Error')
        updateDownloadStore(resource.fileUrl, { active: true, status: 'error' })
      }
    })
  })
}

const getDatasetDateBoundry = (datasetList) => {
  if (!datasetList || datasetList.length === 0) {
    return { startDate: null, latestDate: null }
  }

  let startDate = datasetList[0].datasetVersionDate
  let latestDate = datasetList[0].datasetVersionDate

  for (let i = 1; i < datasetList.length; i++) {
    // Only check types of datasets
    if (!datasetList[i]?.type === 'Primary') { continue }

    const currentDate = datasetList[i].datasetVersionDate

    if (currentDate < startDate) {
      startDate = currentDate
    }

    if (currentDate > latestDate) {
      latestDate = currentDate
    }
  }

  return { startDate, latestDate }
}

const getDateFormatByFrequency = (frequency) => {
  switch (frequency) {
    case 'Quarterly':
      return 'QQQ yyyy'
    case 'Monthly':
    case 'Bimonthly':
    case 'Three times a year':
    case 'Semiannually':
      return 'MM/yyyy'
    case 'Annually':
      return 'yyyy'
    default:
      return 'MM/dd/yyyy'
  }
}

const getPlaceholderTextByFrequency = (frequency) => {
  switch (frequency) {
    case 'Quarterly':
      return 'QQ YYYY'

    case 'Monthly':
    case 'Bimonthly':
    case 'Three times a year':
    case 'Semiannually':
      return 'MM/YYYY'

    case 'Annually':
      return 'YYYY'
    default:
      return 'MM/DD/YYYY'
  }
}

function sortResourcesByVersionDate (resources) {
  resources?.sort(function (a, b) {
    if (!a?.datasetVersionDate || !b?.datasetVersionDate) { return 0 }

    var dateA = new Date(a.datasetVersionDate)
    var dateB = new Date(b.datasetVersionDate)

    // Sort dates desc order
    if (dateA > dateB) {
      return -1
    } else if (dateA < dateB) {
      return 1
    } else {
      // If there is a tie in dates, sort by title
      if (a?.title < b?.title) {
        return -1
      } else if (a.title > b.title) {
        return 1
      } else {
        return 0
      }
    }
  })

  return resources
}

function getFileSizeSumFromResources (resources) {
  // Get an array of file sizes for the resources
  const resourceByTypeFileSizes = getResourceFileSizeByType(resources)
  // Add up the resourceByTypeFileSizes
  return Object.values(resourceByTypeFileSizes)
    .filter(value => typeof value === 'number' && !isNaN(value))
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
}

export {
  getResourcesByType,
  getResourceFileSizeByType,
  formatBytes,
  getFileExtension,
  buildVersionsSelectList,
  getResourcesForOneVersion,
  getResourcesInRangeOfVersions,
  getResourcesInDateRange,
  getVersionIdsInRange,
  removeQueryParamFromUrlObj,
  downloadFile,
  downloadWithBrowser,
  removeDuplicateFileResources,
  getTrimmedDownIdsExportUrls,
  formatChildrenResourcesIntoNodes,
  formatResourcesIntoNodes,
  getDisplayNameFromResource,
  downloadResourceFiles,
  getResourcesFromNodeSelection,
  rollUpAdditionalResources,
  modifyIfAdditionalResource,
  orderCategories,
  orderCategoriesFromArray,
  getDatasetDateBoundry,
  getDateFormatByFrequency,
  getPlaceholderTextByFrequency,
  sortResourcesByVersionDate,
  getFileSizeSumFromResources
}
