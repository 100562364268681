import React from 'react'
import PropTypes from 'prop-types'
import inpatientToolImage from '../../../assets/Search_ToolIcon_InpatientHospital.svg'
import prescriberToolImage from '../../../assets/Search_ToolIcon_MedicarePartD.svg'
import msStateCountyImage from '../../../assets/Search_ToolIcon_MSStateCounty.svg'
import physicianToolImage from '../../../assets/Search_ToolIcon_Physician.svg'
import optoutToolImage from '../../../assets/Search_ToolIcon_Optout.svg'
import revalidationToolImage from '../../../assets/Search_ToolIcon_Revalidation.svg'
import mmdToolImage from '../../../assets/Search_ToolIcon_mmd.svg'
import defaultImage from '../../../assets/chart-icon-inverted.svg'
import BasicSearchResult from './BasicSearchResult'

import './LookupToolResult.scss'
import { toolTypes } from '../../../utilities/lookupToolUtilities'
import config from '../../../config'

const { apiSite } = config

const {
  inpatient,
  prescriber,
  physician,
  optout,
  revalidation,
  mmdHospital,
  mmdPopulation,
  marketSaturationStateCounty,
  marketSaturationCBSA
} = toolTypes

function getToolImage (toolType) {
  switch (toolType) {
    case inpatient:
      return inpatientToolImage
    case prescriber:
      return prescriberToolImage
    case physician:
      return physicianToolImage
    case optout:
      return optoutToolImage
    case revalidation:
      return revalidationToolImage
    case mmdHospital:
    case mmdPopulation:
      return mmdToolImage
    case marketSaturationStateCounty:
    case marketSaturationCBSA:
      return msStateCountyImage
    default:
      // placeholder/fallback image
      return defaultImage
  }
}

const LookupToolResult = ({ data }) => {
  const {
    description,
    slug,
    title,
    type,
    tool_type, // eslint-disable-line
    icon_inverted: iconInverted // eslint-disable-line
  } = data

  const imgSrc = iconInverted ? apiSite + iconInverted : getToolImage(tool_type)

  const resultProps = {
    imgSrc,
    imgAlt: title,
    description,
    slug,
    title,
    type,
    btnText: 'Launch Tool'
  }

  return <BasicSearchResult className='LookupToolResult' {...resultProps} />
}

LookupToolResult.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    type: PropTypes.string,
    tool_type: PropTypes.string.isRequired,
    icon_inverted: PropTypes.string
  })
}

export default LookupToolResult
