import Analytics from './analyticsUtilities'
import { formatBytes, SelectedOptionTypes } from '../components/common/DownloadModal/downloadModalHelpers'

const track = (eventData, utag) => {
  if (utag) {
    Analytics.trackEvent(eventData, utag)
  }
}

export const trackContactClick = (buttonLabel, contactHref, utag) => {
  const eventData = {
    link_text: buttonLabel,
    link_url: contactHref,
    grouping: 'Main Menu',
    ga_event_category: 'Support',
    ga_event_action: 'click',
    ga_event_label: buttonLabel
  }

  track(eventData, utag)
}

export const trackAnchorClick = (text, utag) => {
  const eventData = {
    link_text: text,
    grouping: 'Anchor Links',
    ga_event_category: 'Anchor Link',
    ga_event_action: 'click',
    ga_event_label: text
  }

  track(eventData, utag)
}

export const trackDownloadModalClick = ({ fileName, fileSize, fileMime, datasetVersionLabel, datasetName, frequency, startDate, endDate, selectedOption }, selectedResources, utag) => {
  const eventData = {
    grouping: 'Downloads',
    ga_event_category: 'File Download',
    ga_event_action: 'click',
    ga_event_label: 'Download Files',
    dataset_name: datasetName,
    file_type: fileMime,
    file_name: fileName,
    file_size: `${formatBytes(fileSize)}`,
    selected_file_names: selectedResources,
    start_date: (startDate && selectedOption === SelectedOptionTypes.Option3) ? startDate : '',
    end_date: (endDate && selectedOption === SelectedOptionTypes.Option3) ? endDate : '',
    ...(frequency ? { dataset_frequency: frequency } : {}),
    ...(selectedOption ? { selected_option: selectedOption } : {}) // latest_dataset, latest_dataset_resources, custom
  }
  track(eventData, utag)
}

export const trackDownloadExportClick = (eventLabel, datasetName, utag) => {
  const eventData = {
    grouping: 'Downloads',
    ga_event_category: 'File Download',
    ga_event_action: 'click',
    ga_event_label: eventLabel,
    dataset_name: datasetName,
    file_type: 'zip',
    file_name: `${datasetName}.zip`
  }

  track(eventData, utag)
}

export const trackIntendedDownloadClick = (label, utag) => {
  const eventData = {
    ga_event_category: 'Internal link',
    ga_event_action: 'click',
    ga_event_label: label,
    grouping: 'Downloads'
  }

  track(eventData, utag)
}

export const trackMobileMenuClick = (isMenuOpen, utag) => {
  const eventData = {
    ga_event_category: 'Hamburger menu',
    ga_event_action: 'click',
    ga_event_label: !isMenuOpen ? 'open' : 'closed',
    grouping: 'Main Menu'
  }

  track(eventData, utag)
}

// open, close, refresh search modal
export const trackMenuSearchIconClick = (label, utag) => {
  const eventData = {
    ga_event_category: 'Internal link',
    ga_event_action: 'click',
    ga_event_label: label,
    grouping: 'Search'
  }

  track(eventData, utag)
}

export const trackInternalLinkClick = (event, path, text, utag) => {
  const eventData = {
    ga_event_category: 'Internal link',
    ga_event_action: 'click',
    ga_event_label: text,
    grouping: 'Internal Links',
    link_url: path,
    link_text: text
  }

  if (event && event.grouping === 'Main Menu') {
    eventData.ga_event_category = event.category
    eventData.grouping = event.grouping
  }

  track(eventData, utag)
}

export const trackExternalLinkClick = (e, utag) => {
  const path = e.currentTarget.getAttribute('href')
  const text = e.currentTarget.innerText !== '' ? e.currentTarget.innerText : e.currentTarget.innerHTML
  const eventData = {
    ga_event_category: 'External link',
    ga_event_action: 'click',
    ga_event_label: text,
    grouping: 'External Links',
    link_url: path,
    link_text: text
  }

  track(eventData, utag)
}

// term = user entered, typeahead = typeahead value
export const trackTypeaheadClicks = (event, { term, typeahead }, searchButton, utag) => {
  const eventData = {
    grouping: event ? event.grouping : 'Typeahead',
    ga_event_category: event ? event.category : 'Typeahead',
    ga_event_action: 'click',
    ga_event_label: searchButton ? 'SearchIcon' : typeahead,
    search_term: term
  }

  if (searchButton && term.length === 0) {
    eventData.ga_event_category = 'Search'
  }

  track(eventData, utag)
}

export const trackDataBarClick = (label, utag) => {
  const eventData = {
    ga_event_category: 'Internal link',
    ga_event_action: 'click',
    ga_event_label: label
  }

  track(eventData, utag)
}

export const trackInteractiveDataClick = (label, utag, columns) => {
  const eventData = {
    ga_event_category: 'Interactive Dataset',
    ga_event_action: 'click',
    ga_event_label: label
  }
  if (columns) {
    eventData.selected_manage_columns = columns
  }

  track(eventData, utag)
}

export const trackInteractiveFiltersClick = (label, filters, utag) => {
  const eventData = {
    ga_event_category: 'Interactive Dataset',
    ga_event_action: 'click',
    ga_event_label: label
  }
  if (filters) {
    eventData.selected_filters = filters.selected_filters
    eventData.selected_manage_columns = filters.selected_manage_columns
  }

  track(eventData, utag)
}

export const trackInteractiveDataSearch = (action, label, utag) => {
  const eventData = {
    ga_event_category: 'Interactive Dataset',
    ga_event_action: action,
    ga_event_label: label
  }

  track(eventData, utag)
}

export const trackExportInteractiveData = (action, label, utag) => {
  const eventData = {
    ga_event_category: 'Downloads',
    ga_event_action: action,
    ga_event_label: label
  }

  track(eventData, utag)
}

export const trackInteractiveDataCopyFilteredView = (utag) => {
  trackInteractiveDataSearch('click', 'copy-view', utag)
}

export const trackInteractiveDataResetView = (utag) => {
  trackInteractiveDataSearch('click', 'reset-view', utag)
}
