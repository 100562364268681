import React from 'react'
import LinkHandler from '../../common/LinkHandler/LinkHandler'
import withDisplayContext from '../../../hocs/withDisplayContext/withDisplayContext'
import {
  propTypeLinks
} from './propTypes'
import { ReactComponent as BoxEmpty } from '../../../assets/fontawesome/custom/stars.svg'
import { featureIsEnabled } from '../../../stores/siteStore/siteStore'

import cx from 'classnames'
import './HeaderMenu.scss'

class HeaderMenu extends React.PureComponent {
  renderLinks () {
    const { links } = this.props
    return (
      <>
        <div className='HeaderMenu__linkGroup primary'>
          {this.renderLinkGroup(links.primary, 'primary')}
        </div>
        <div className='HeaderMenu__linkGroup secondary'>
          {this.renderLinkGroup(links.secondary, 'secondary')}
          {featureIsEnabled('whatsNew') && this.renderWhatsNewLink('secondary')}
        </div>
      </>
    )
  }

  renderWhatsNewLink (position) {
    return (
      <div key='whatsNew' className={cx('HeaderMenu__link whatsNew ', position)}>
        <span style={{ paddingRight: 6 }} className='starIcon'><BoxEmpty /></span>
        <div onClick={() => { console.log('TODO: Add side drawer for WhatsNew') }}>
          <div key='whatsNew' className={cx('HeaderMenu__link__text__', position)}>
            What's New
          </div>
        </div>
      </div>
    )
  }

  renderLinkGroup (linkGroup = [], position) {
    return linkGroup.map((link) => {
      const { id, name, url } = link
      return (
        <div key={id} className={cx('HeaderMenu__link ', position)}>
          <LinkHandler
            noBs
            href={url}
            event={{ grouping: 'Main Menu', category: 'Menu Item' }}
          >
            <div key={id} className={cx('HeaderMenu__link__text__', position)}>
              {name}
            </div>
          </LinkHandler>
        </div>
      )
    })
  }

  render () {
    return (
      <div className='HeaderMenu'>
        {this.renderLinks()}
      </div>
    )
  }
}

HeaderMenu.propTypes = {
  links: propTypeLinks
}

HeaderMenu.defaultProps = {
  links: {}
}

export { HeaderMenu }

export default withDisplayContext(HeaderMenu)
